import React, { Component } from 'react'
import ReactMarkdown from 'react-markdown'

export default class Attribute extends Component {
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }
  render() {
    let attrClass = this.props.light
      ? 'attribute attribute--light'
      : 'attribute'

    attrClass = `${attrClass} ${this.props.value ? '' : 'hidden'}`
    return (
      <div className={attrClass}>
        <div className='attribute__name'>
          {this.capitalizeFirstLetter(this.props.name)}
        </div>
        <ReactMarkdown className='attribute__value' children={this.props.value} />
      </div>
    )
  }
}
